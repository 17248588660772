import React from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import ProtectedRoute from "./components/protected_route";
import NotProtectedRoute from './components/not_protected_route';
import ChangePassword from './screens/settings/change_password';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import VerificationCode from './pages/VerificationCode';
import ResetPassword from './pages/ResetPassword';
import Settings from './pages/settings';
import PageNotFound from './pages/PageNotFound';
import Subscription from "./pages/Subscription";
import Payment from './pages/Payment';

export function routing(authData) {
    return (
            <Switch>
                <ProtectedRoute isAuth={authData} path="/Home" component={Home}/>
                <ProtectedRoute isAuth={authData} path="/ChangePassword" component={ChangePassword} />
                <ProtectedRoute isAuth={authData} path="/Settings" component={Settings} />
                <ProtectedRoute isAuth={authData} path="/subscription" component={Subscription} />
                <ProtectedRoute isAuth={authData} path="/Payment" component={Payment} />
                <NotProtectedRoute  isAuth={authData} path="/ResetPassword" component={ResetPassword} />
                <NotProtectedRoute  isAuth={authData} path="/VerificationCode" component={VerificationCode} />
                <NotProtectedRoute  isAuth={authData} path="/ForgotPassword" component={ForgotPassword} />
                <NotProtectedRoute  isAuth={authData} path="/Signup" component={Signup} />
                <NotProtectedRoute  isAuth={authData} path="/Login" component={Login} />
                <Redirect path="/" exact to={authData ? "/Payment" : "/login"} />
                <Redirect to={authData ? "/Payment" : "/login"} />
                <Redirect path="*" component={PageNotFound} />

            </Switch>
    );
}
