import React, { Component, } from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import Drawer from '@material-ui/core/Drawer';
import { ClickAwayListener } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocalizedStrings from 'react-localization';
import './style.scss';
import clsx from 'clsx';
import { withStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import TempProfile from '../../assets/images/images.jfif';
import { ReactComponent as FlightDeptIcon } from '../../assets/images/drawer/ic_flight_dept.svg';
import { ReactComponent as MarkAvailIcon } from '../../assets/images/drawer/ic_mark_avail.svg';
import { ReactComponent as HomeIcon } from '../../assets/images/drawer/ic_home.svg';
import { ReactComponent as HiringBoardIcon } from '../../assets/images/drawer/ic_hiring_board.svg';
import { ReactComponent as TripCalIcon } from '../../assets/images/drawer/ic_trip_cal.svg';
import { ReactComponent as ChatIcon } from '../../assets/images/drawer/ic_chat.svg';
import { ReactComponent as SubscriptionIcon } from '../../assets/images/drawer/ic_subscription.svg';
import { ReactComponent as SettingsIcon } from '../../assets/images/drawer/ic_settings.svg';
import { ReactComponent as ContactIcon } from '../../assets/images/drawer/ic_contactLC.svg';
import { ReactComponent as PrivacyPolicyIcon } from '../../assets/images/drawer/ic_privacy_policy.svg';
import { ReactComponent as LogoutIcon } from '../../assets/images/drawer/ic_logout.svg';
import { ReactComponent as TermsOfServiceIcon } from '../../assets/images/drawer/ic_terms_of_service.svg';
import { ReactComponent as PaymentIcon } from '../../assets/images/drawer/ic_payment.svg';
import { ReactComponent as PaymentIconSelected } from '../../assets/images/drawer/ic_payment_selected.svg';
import { ReactComponent as FlightDeptIconSelected } from '../../assets/images/drawer/ic_flight_dept_selected.svg';
import { ReactComponent as MarkAvailIconSelected } from '../../assets/images/drawer/ic_mark_avail_selected.svg';
import { ReactComponent as HomeIconSelected } from '../../assets/images/drawer/ic_home_selected.svg';
import { ReactComponent as HiringBoardIconSelected } from '../../assets/images/drawer/ic_hiring_board_selected.svg';
import { ReactComponent as TripCalIconSelected } from '../../assets/images/drawer/ic_trip_cal_selected.svg';
import { ReactComponent as ChatIconSelected } from '../../assets/images/drawer/ic_chat_selected.svg';
import { ReactComponent as SubscriptionIconSelected } from '../../assets/images/drawer/ic_subscription_selected.svg';
import { ReactComponent as SettingsIconSelected } from '../../assets/images/drawer/ic_settings_selected.svg';
import { ReactComponent as ContactIconSelected } from '../../assets/images/drawer/ic_contactLC_selected.svg';
import { ReactComponent as PrivacyPolicyIconSelected } from '../../assets/images/drawer/ic_privacy_policy_selected.svg';
import { ReactComponent as TermsOfServiceIconSelected } from '../../assets/images/drawer/ic_terms_of_service_selected.svg';

import TopBar from '../top-bar';
import { setAuthToken, getProfile } from "../../utils/api-helper";
import { saveAuthData, saveLoginUserData } from '../../actions/auth';
import { clearAllLocalStorage, get } from '../../utils/local-storage-helper';
import { showToast, showSpinner, confirmAlert, capitalizeText } from '../../utils/common_helper';
import { data } from '../../utils/locales/data';
import { terms_privacy_base_url } from '../../utils/constants';
import FirebaseImage from '../../components/firebase_fast_image_view/index'
import MediaQuery from 'react-responsive'
import { createContext } from 'react';


const drawerWidth = 288;
const drawerWidthMobile = 180;
const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerMobile: {
    width: drawerWidthMobile,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerOpenMobile: {
    width: drawerWidthMobile,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    // width: theme.spacing(7) + 1,
    // [theme.breakpoints.up('sm')]: {
    //     width: theme.spacing(9) + 1,
    // },
    width: 74,
  },


  MuiPaperRoot: {
    marginLeft: '15vw',
    marginTop: '65px',
    zIndex: '999',
    borderRadius: 5,
    border: '1px solid #ebebeb',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',

  },
  MuiPaperRootMobile: {
    marginLeft: '0vw',
    marginTop: '50px',
    zIndex: '999',
    borderRadius: 5,
    border: '1px solid #ebebeb',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',

  },
  mainDrawerCSS: {
    position: 'absolute'
  }


});

let strings = new LocalizedStrings(data);

const pilotMenuList = [
  // { name: 'Home', icon: <HomeIcon />, selectedIcon: <HomeIconSelected /> },
  // { name: 'Flight Department', icon: <FlightDeptIcon />, selectedIcon: <FlightDeptIconSelected /> },
  // { name: 'Mark Availability', icon: <MarkAvailIcon />, selectedIcon: <MarkAvailIconSelected /> },
  // { name: 'Hiring Board', icon: <HiringBoardIcon />, selectedIcon: <HiringBoardIconSelected /> },
  // { name: 'Trip Calendar', icon: <TripCalIcon />, selectedIcon: <TripCalIconSelected /> },
  // { name: 'Chat', icon: <ChatIcon />, selectedIcon: <ChatIconSelected /> },
  { name: 'Payment', icon: <PaymentIcon />, selectedIcon: <PaymentIconSelected /> },
  { name: 'Subscription', icon: <SubscriptionIcon />, selectedIcon: <SubscriptionIconSelected /> },
  { name: 'Settings', icon: <SettingsIcon />, selectedIcon: <SettingsIconSelected /> },
  // { name: 'Contact LuxeCrews', icon: <ContactIcon />, selectedIcon: <ContactIconSelected /> },
  { name: 'Terms of Service', icon: <TermsOfServiceIcon />, selectedIcon: <TermsOfServiceIconSelected /> },
  { name: 'Privacy Policy', icon: <PrivacyPolicyIcon />, selectedIcon: <PrivacyPolicyIconSelected /> },
  { name: 'Logout', icon: <LogoutIcon /> },
];
const generalMenuList = [
  // { name: 'Home', icon: <HomeIcon />, selectedIcon: <HomeIconSelected /> },
  // { name: 'Flight Department', icon: <FlightDeptIcon />, selectedIcon: <FlightDeptIconSelected /> },
  // { name: 'Hiring Board', icon: <HiringBoardIcon /> },
  // { name: 'Trip Calendar', icon: <TripCalIcon />, selectedIcon: <TripCalIconSelected /> },
  // { name: 'Chat', icon: <ChatIcon />, selectedIcon: <ChatIconSelected /> },
  { name: 'Payment', icon: <PaymentIcon />, selectedIcon: <PaymentIconSelected /> },
  { name: 'Subscription', icon: <SubscriptionIcon />, selectedIcon: <SubscriptionIconSelected /> },
  { name: 'Settings', icon: <SettingsIcon />, selectedIcon: <SettingsIconSelected /> },
  // { name: 'Contact LuxeCrews', icon: <ContactIcon />, selectedIcon: <ContactIconSelected /> },
  { name: 'Terms of Service', icon: <TermsOfServiceIcon />, selectedIcon: <TermsOfServiceIconSelected /> },
  { name: 'Privacy Policy', icon: <PrivacyPolicyIcon />, selectedIcon: <PrivacyPolicyIconSelected /> },
  { name: 'Logout', icon: <LogoutIcon /> },
];

class DrawerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.state = {
      open: false,
      isHoverOpen: false,
      selectedMenu: 'Payment',
      showConfirmAlert: false,
      profileData: null,
      isDataFatched: false,
      isMenuClicked: false,
      drawerStatusMobile: false
    }
  }



  componentDidUpdate(prevProps) {
    if (prevProps.authData !== this.props.authData && this.props.authData) {
      this.getProfileData();
    }
  }
  componentDidMount() {
    this.getProfileData();
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState({ showConfirmAlert: false });
    });
  }
  componentWillUnmount() {
    this.unlisten();
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleDrawerOpen = () => {
    console.log("dynamics", this.state.height, this.state.width)
    this.setState({ isHoverOpen: false });
    this.setState({ open: !this.state.open, isMenuClicked: !this.state.isMenuClicked });
    this.props.isStaticOpen(!this.state.isMenuClicked)
  };

  onHoverOpen = () => {
    this.setState({ open: true });
    this.setState({ isHoverOpen: true })
  }

  onMenuSelect = (menu) => {
    if (menu?.name == 'Logout') {
      this.setState({ showConfirmAlert: true });
    } else if (menu?.name == 'Payment') {
      this.setState({ selectedMenu: menu.name });
      this.props.history.push('/Payment');
    } else if (menu?.name == 'Subscription') {
      this.setState({ selectedMenu: menu.name });
      this.props.history.push('/subscription');
    } else if (menu?.name == 'Settings') {
      this.setState({ selectedMenu: menu.name });
      this.props.history.push('/Settings');
    } else if (menu?.name == 'Home') {
      this.setState({ selectedMenu: menu.name });
      this.props.history.push('/Home');
    } else if (menu?.name == 'Privacy Policy' || menu?.name == 'Terms of Service') {
      // this.setState({ selectedMenu: menu.name });
      this.openURLS(menu.name)
    } else {
      alert("Coming Soon...")
    }
  }

  openURLS = (type) => {
    let link = document.createElement('a');
    link.setAttribute('target', '_blank');

    if (type === 'Privacy Policy') {
      link.setAttribute('href', `${terms_privacy_base_url}privacy-policy/`);
      link.click();
    } else if (type === 'Terms of Service') {
      link.setAttribute('href', `${terms_privacy_base_url}terms-of-service/`);
      link.click();
    }
  }

  onConfirmLogout = () => {
    setAuthToken(undefined, undefined);
    clearAllLocalStorage();
    this.props.saveAuthData(null);
    this.props.saveLoginUserData(null);
    this.props.history.push('/');
  }

  getProfileData = () => {
    let role = get("LUXECREWS_USER_ROLE");
    this.setState({ isLoading: true });

    getProfile(role).then(response => {
      if (response.code === 200) {
        this.setState({ isDataFatched: true });
        this.props.saveLoginUserData(response.data);
        var updatedProfileData = response.data ? response.data : null;
        this.setState({ profileData: updatedProfileData })
      }
    }).catch(this.handleError);
  }



  handleError = (error) => {
    this.setState({ isDataFatched: true }, () => {
      if (error?.message) {
        showToast(error?.message, "error");
      }
    });
  }

  onCancelLogout = () => {
    this.setState({ showConfirmAlert: false });
  }

  handleDrawerClose =() => {
    this.setState({drawerStatusMobile:false})
  }

  render() {
    const { classes, theme, userData } = this.props;
    const { selectedMenu, profileData } = this.state;

    return (
      <>

        <TopBar
          handleDrawerOpen={this.handleDrawerOpen}
          onHoverOpen={this.onHoverOpen}
          onMenuSelect={this.onMenuSelect}
          pilotMenuList={pilotMenuList}
          companyMenuList={generalMenuList}
          role={userData?.role}
          selectedMenu={this.state.selectedMenu}
          // MobileStatus={this.state.drawerStatusMobile}
          pageState={(i) =>
            // console.log("pageStare",i) }
            this.setState({ drawerStatusMobile: i })}
        />
        <Drawer id="drawerVisible"
          onMouseOver={
            !this.state.isMenuClicked
              ? () => {
                this.setState({ open: true })
              }
              : null
          }
          onMouseLeave={
            !this.state.isMenuClicked
              ? () => {
                this.setState({ open: false })
              }
              : null
          }
          variant="permanent"
          className={clsx(this.state.width > 480 ? classes.drawer : classes.drawerMobile, {
            [this.state.width > 480 ? classes.drawerOpen : classes.drawerOpenMobile]: this.state.open,
            [classes.drawerClose]: !this.state.open,
            [classes.mainDrawerCSS]: !this.state.isMenuClicked
            // [classes.drawerOpen]: this.state.absoluteOpen,
            // [classes.drawerClose]: !this.state.absoluteOpen,
          })}

          classes={{
            paper: clsx(this.state.width > 480 ? classes.MuiPaperRoot : classes.MuiPaperRootMobile, {
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            }),
          }}
        >
          <div
            className="d-flex drawer-profile"
            style={
              this.state.open
                ? { padding: "16px 26px" }
                : { padding: "16px 7px" }
            }
          >
            <div className="drawer-profile-img-div">
              <FirebaseImage
                src={
                  userData && userData?.profileImagePath
                    ? userData?.profileImagePath
                    : null
                }
                className={"drawer-profile-img"}
              />
            </div>
            <div style={{ marginLeft: "15px", position: "relative" }}>
              <span className="drawer-profile-name-align">
                <div className="drawer-profile-name">
                  {" "}
                  {`${userData && userData?.firstName
                    ? userData.firstName
                    : ""
                    } ${userData && userData?.lastName ? userData.lastName : ""
                    }`}
                </div>
                <div className="drawer-profile-desi">  {`${userData && userData?.role ? capitalizeText(userData.role) : ''} `} </div>

              </span>
            </div>
          </div>
          <Divider />
          {userData?.role === "PILOT" || userData?.role === "FLIGHT_ATTENDANT" ? (
            <List className={"menu-list-custom"}>
              {pilotMenuList.map((menu, index) => (
                <ListItem
                  key={menu?.name}
                  onClick={() => {
                    this.onMenuSelect(menu);
                  }}
                  className={
                    selectedMenu == menu?.name ? "selected-menu" : ""
                  }
                >
                  <ListItemIcon>{selectedMenu == menu?.name ? menu?.selectedIcon : menu?.icon}</ListItemIcon>
                  <ListItemText
                    primary={menu?.name}
                    className={
                      pilotMenuList?.length &&
                        index === pilotMenuList.length - 1
                        ? "logout-text"
                        : ""
                    }
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <List className={"menu-list-custom"}>
              {generalMenuList.map((menu, index) => (
                <ListItem
                  key={menu?.name}
                  onClick={() => {
                    this.onMenuSelect(menu);
                  }}
                  className={
                    selectedMenu == menu?.name ? "selected-menu" : ""
                  }
                >
                  <ListItemIcon>{menu?.icon}</ListItemIcon>
                  <ListItemText
                    primary={menu?.name}
                    className={
                      generalMenuList?.length &&
                        index === generalMenuList.length - 1
                        ? "logout-text"
                        : ""
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
          <Divider />
        </Drawer>

        <MediaQuery maxWidth={480}>
          {this.state.drawerStatusMobile == true &&
              <Drawer open={true}
                style={{ width: 400, position: "absolute" }}
                // onMouseOver={
                //   !this.state.isMenuClicked
                //     ? () => {
                //       this.setState({ open: true })
                //     }
                //     : null
                // }
                onMouseLeave={

                  !this.state.isMenuClicked
                    ? () => {
                      this.setState({ drawerStatusMobile: !this.state.drawerStatusMobile })
                    }
                    : null
                }
                variant="temporary"
                onEscapeKeyDown={this.handleDrawerClose}
                onBackdropClick={this.handleDrawerClose}
              // variant="permanent"
              // className={classes.drawerMobile
              // [this.state.width > 480 ? classes.drawerOpen : classes.drawerOpenMobile]: this.state.open,
              // [classes.drawerClose]: !this.state.open,
              // [classes.mainDrawerCSS]: !this.state.isMenuClicked
              // [classes.drawerOpen]: this.state.absoluteOpen,
              // [classes.drawerClose]: !this.state.absoluteOpen,
              // }

              // classes={{
              //   paper: clsx(this.state.width > 480 ? classes.MuiPaperRoot : classes.MuiPaperRootMobile, {
              //     [classes.drawerOpen]: this.state.open,
              //     [classes.drawerClose]: !this.state.open,
              //   }),
              // }}
              >
                <div
                  className="d-flex drawer-profile"
                  style={
                    this.state.open
                      ? { padding: "8px 6px", width: 280 }
                      : { padding: "8px 7px", width: 280 }
                  }
                >
                  <div className="drawer-profile-img-div">
                    <FirebaseImage
                      src={
                        userData && userData?.profileImagePath
                          ? userData?.profileImagePath
                          : null
                      }
                      className={"drawer-profile-img"}
                    />
                  </div>
                  <div style={{ marginLeft: "15px", position: "relative" }}>
                    <span className="drawer-profile-name-align">
                      <div className="drawer-profile-name">
                        {" "}
                        {`${userData && userData?.firstName
                          ? userData.firstName
                          : ""
                          } ${userData && userData?.lastName ? userData.lastName : ""
                          }`}
                      </div>
                      <div className="drawer-profile-desi">  {`${userData && userData?.role ? capitalizeText(userData.role) : ''} `} </div>

                    </span>
                  </div>
                </div>
                <Divider />
                {userData?.role === "PILOT" || userData?.role === "FLIGHT_ATTENDANT" ? (
                  <List className={"menu-list-custom"}>
                    {pilotMenuList.map((menu, index) => (
                      <ListItem
                        key={menu?.name}
                        onClick={() => {
                          this.setState({ drawerStatusMobile: false });
                          this.onMenuSelect(menu);
                        }}
                        className={
                          selectedMenu == menu?.name ? "selected-menu" : ""
                        }
                      >
                        <ListItemIcon>{selectedMenu == menu?.name ? menu?.selectedIcon : menu?.icon}</ListItemIcon>
                        <ListItemText
                          primary={menu?.name}
                          className={
                            pilotMenuList?.length &&
                              index === pilotMenuList.length - 1
                              ? "logout-text"
                              : ""
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <List className={"menu-list-custom"}>
                    {generalMenuList.map((menu, index) => (
                      <ListItem
                        key={menu?.name}
                        onClick={() => {
                          this.setState({ drawerStatusMobile: !this.state.drawerStatusMobile });
                          this.onMenuSelect(menu);
                        }}
                        className={
                          selectedMenu == menu?.name ? "selected-menu" : ""
                        }
                      >
                        <ListItemIcon>{menu?.icon}</ListItemIcon>
                        <ListItemText
                          primary={menu?.name}
                          className={
                            generalMenuList?.length &&
                              index === generalMenuList.length - 1
                              ? "logout-text"
                              : ""
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
                <Divider />
              </Drawer>
          }
        </MediaQuery>
        {confirmAlert(
          strings.logout_msg,
          "Yes",
          "Cancel",
          this.state.showConfirmAlert,
          "",
          () => this.onConfirmLogout(),
          () => this.onCancelLogout()
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  userData: state.auth.loginUserData
});

export default withRouter(compose(connect(mapStateToProps, { saveAuthData, saveLoginUserData }), (withStyles(styles, { withTheme: true })))(DrawerComponent));
//  ,(withStyles(styles, { withTheme: true })(DrawerComponent));